import React, { Component } from 'react';
import CustomTable from '../components/CustomTable';
import LoadingSpinner from '../components/LoadingSpinner';
import { postUser, updateUser, getUsersParams, getPdfQuestionForm, deleteUser, clinicDeleteUser } from '../services/http/authService';
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';
import { getCurrentUser } from '../services/http/authService';
import { DISALLOWED_EMAILS_ANTIGEN } from '../constants/controllers';
import EditPatientModal from '../components/EditPatientModal';
import PreviewPatientModal from '../components/PreviewPatientModal';
import { isMobile } from 'react-device-detect';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorToast, successToast } from '../constants/toasts';
import { Col, Row } from 'react-bootstrap';
import PDFPreviewModal from '../components/PDFPreviewModal';

export default class Clinic extends Component {

  PER_PAGE = 50;

  state = {
    isLoading: false,
    isTableLoading: false,
    isPrintingLoading: false,
    display: 0, // 0 - pending, 1 - resolved
    test_type_display: 0, // 0 - PCR, 1 - ANTIGEN
    first_name: '',
    last_name: '',
    passport_num: '',
    email: '',
    phone: '',
    date_of_birth: '',
    date_of_test: Date.now(),
    test_maker: '',
    test_type: '',
    test_reason: null,
    is_positive: null,
    gender: '',
    users: [],
    usersDisplayed: [],
    sort_asc: false,
    last_sort: '',
    protocol_num: null,
    activePage: 1,
    last_user: null,
    user: null,
    isEditModalShown: false,
    isPreviewModalShown: false,
    isPDFPreviewModalShown: false,
    pdfModalURL: null,
    modalPatient: null // patient object sent to the modal for editting
  };

  componentDidMount() {
    // window.scrollTo(0, 0);
    const user = getCurrentUser();
    this.setState({ user });
    if (DISALLOWED_EMAILS_ANTIGEN.includes(user.email)) {
      this.setState({ test_type: "PCR" });
    }
    this.getUsers("PENDING", "PCR");
  }

  getUsers = (pendingOrResolved, testType, isFilterSwitching = false) => {
    if (isFilterSwitching) {
      this.setState({ isTableLoading: true });
    } else {
      this.setState({ isLoading: true });
    }
    getUsersParams(pendingOrResolved, testType).then(this.onGetUsersSuccess).catch(this.onGetUsersError);
  }

  onGetUsersError = (err) => {
    this.setState({ isLoading: false, isTableLoading: false });
    if (err && err.message) {
      if (err.message.includes('No news') || err.message.includes('Failed to fetch')) {
        return;
      }
    }
    errorToast(err.messag);
  };

  onGetUsersSuccess = (res) => {
    const pageNumber = this.state.activePage;
    const usersArray = res.result.reverse()
    this.setState({
      users: usersArray,
      activePage: pageNumber,
      usersDisplayed: usersArray.slice(this.getStartIndex(pageNumber), this.getEndIndex(pageNumber)),
      isLoading: false,
      isTableLoading: false
    });
  };

  getStartIndex = (pageNumber) => {
    return (pageNumber - 1) * this.PER_PAGE;
  }

  getEndIndex = (pageNumber) => {
    return pageNumber * this.PER_PAGE;
  }

  updateDisplay(display, test_type_display) {
    const testTypes = ["PCR", "ANTIGEN"];
    const statuses = ["PENDING", "RESOLVED"];
    const chosenType = testTypes[test_type_display];
    let chosenStatus = statuses[display];
    if (chosenType == "ANTIGEN") {
      chosenStatus = "RESOLVED";
    }
    this.getUsers(chosenStatus, chosenType, true);
    this.setState({ display, activePage: 1, test_type_display });
  }

  switchDisplay(targetDisplay) {
    if (targetDisplay != this.state.display) {
      this.updateDisplay(targetDisplay, this.state.test_type_display);
    }
  }

  switchTestType(targetTestType) {
    if (targetTestType != this.state.test_type_display) {
      this.updateDisplay(1, targetTestType);
    }
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber, usersDisplayed: this.state.users.slice(this.getStartIndex(pageNumber), this.getEndIndex(pageNumber)) });
  }

  sortUsers = (e, by) => {
    e.preventDefault();
    const { usersDisplayed, last_sort, sort_asc } = this.state;
    let sorted;
    let sort_asc_state = sort_asc;
    if (by == last_sort) {
      sort_asc_state = !sort_asc_state;
    }
    sorted = usersDisplayed.sort((a, b) => {
      if (a[by] < b[by]) {
        return sort_asc_state ? -1 : 1;
      }
      if (a[by] > b[by]) {
        return sort_asc_state ? 1 : -1;
      }
      return 0;
    });
    this.setState({ usersDisplayed: sorted, last_sort: by, sort_asc: sort_asc_state });
  };

  onSendClick = async (e) => {
    let { first_name, last_name, phone, date_of_birth, test_maker, test_type, is_positive, gender, date_of_test, passport_num, protocol_num, test_reason } = this.state;

    const input = {
      ...this.state
    }
    if (this.state.user && DISALLOWED_EMAILS_ANTIGEN.includes(this.state.user.email)) {
      input.test_type = "PCR";
    }

    if (
      first_name.length == 0 ||
      last_name.length == 0 ||
      phone.length == 0 ||
      date_of_birth.length == 0 ||
      date_of_test.length == 0 ||
      (!DISALLOWED_EMAILS_ANTIGEN.includes(this.state.user.email) && test_type.length == 0) ||
      gender.length == 0 ||
      (is_positive == null && test_type == "ANTIGEN") ||
      (test_type == "PCR" && (test_reason == null || test_reason.length == 0))
    ) {
      errorToast('Molimo popunite sva polja');
      return;
    }
    // window.scrollTo(0, 0);

    this.setState({ isLoading: true, isTableLoading: false, isEditModalShown: false });
    postUser(input)
      .then((res) => {
        this.onPostSuccess(res.result);
      })
      .catch((err) => {
        this.onPostFailed(err.message);
      });
  };

  onNewUserSendClick = async (e, userInfo) => {
    let { first_name, last_name, phone, date_of_birth, gender, date_of_test, passport_num, email, id, test_reason, test_type } = userInfo;

    if (
      first_name.length == 0 ||
      last_name.length == 0 ||
      phone.length == 0 ||
      date_of_birth.length == 0 ||
      date_of_test.length == 0 ||
      gender.length == 0 ||
      (test_type == "PCR" && (test_reason == null || test_reason.length == 0))
    ) {
      errorToast('Molimo popunite sva polja');
      return;
    }
    // window.scrollTo(0, 0);

    const updateDto = {
      id,
      first_name,
      last_name,
      phone,
      date_of_birth,
      gender,
      date_of_test,
      passport_num,
      test_reason,
      email
    }

    this.setState({ isLoading: true, isTableLoading: false, isEditModalShown: false });
    updateUser(updateDto)
      .then((res) => {
        this.onUpdateSuccess(res.result);
      })
      .catch((err) => {
        this.onUpdateFailed(err.message);
      });
  }

  onUpdateSuccess = (res) => {
    this.onPostSuccess(res, true);
  }

  onUpdateFailed = (res) => {
    this.onPostFailed(res);
  }

  onPostSuccess = (res, edit = false) => {
    if (res.user.test_type == "ANTIGEN") {
      let out = res.user.pdf_link_primary;
      if (res.user.pdf_link_en) {
        out = out + '\n\n' + res.user.pdf_link_en;
      }
      if (edit) {
        successToast(`Pacijent "${res.user.first_name} ${res.user.last_name}" uspješno ažuriran - ANTIGEN`);
      } else {
        successToast(`Pacijent "${res.user.first_name} ${res.user.last_name}" uspješno dodan - ANTIGEN`);
      }
    } else {
      if (edit) {
        successToast(`Pacijent "${res.user.first_name} ${res.user.last_name}" uspješno ažuriran - PCR`);
      } else {
        successToast(`Pacijent "${res.user.first_name} ${res.user.last_name}" uspješno dodan - PCR`);
      }
    }
    this.setState({
      isLoading: false,
      isTableLoading: false,
      error: '',
      first_name: '',
      last_name: '',
      passport_num: '',
      email: '',
      phone: '',
      date_of_birth: '',
      date_of_test: Date.now(),
      test_type: '',
      is_positive: null,
      gender: '',
      protocol_num: null,
      last_user: res.user,
      isEditModalShown: false,
      modalPatient: null
    });
    if (DISALLOWED_EMAILS_ANTIGEN.includes(getCurrentUser().email)) {
      this.setState({ test_type: "PCR" });
    }
    this.updateDisplay(this.state.display, this.state.test_type_display);
  };

  onPostFailed = (err) => {
    console.log(err);
    errorToast('GREŠKA: ' + err);
    this.setState({
      isLoading: false,
      isTableLoading: false,
      error: err,
      first_name: '',
      last_name: '',
      test_reason: null,
      passport_num: null,
      email: '',
      phone: '',
      date_of_birth: '',
      date_of_test: Date.now(),
      test_type: '',
      is_positive: null,
      gender: '',
      protocol_num: null,
      isEditModalShown: false,
      modalPatient: null
    });
    if (DISALLOWED_EMAILS_ANTIGEN.includes(getCurrentUser().email)) {
      this.setState({ test_type: "PCR" });
    }
  };

  handleInputChange = (e, property) => {
    this.setState({ [property]: e.target.value });
  };

  handleDateChange = (e, property) => {
    this.setState({ [property]: e });
  };

  handleSelectTestType = (e) => {
    this.setState({ test_type: e.target.value });
  };

  handleSelectIsPositive = (e) => {
    const { value } = e.target;
    let res = value == 'POZITIVAN';
    this.setState({ is_positive: res });
  };

  handleSelectGender = (e) => {
    this.setState({ gender: e.target.value });
  };

  handleSelectTestReason = (e) => {
    this.setState({ test_reason: e.target.value });
  };

  handleEditClick = (e, patient) => {
    e.preventDefault();
    this.setState({ isEditModalShown: true, modalPatient: patient });
  }

  hideEditModal = () => {
    this.setState({ isEditModalShown: false, modalPatient: null });
  }

  handlePreviewClick = (e, patient) => {
    e.preventDefault();
    this.setState({ isPreviewModalShown: true, modalPatient: patient });
  }

  hidePreviewModal = () => {
    this.setState({ isPreviewModalShown: false, modalPatient: null });
  }

  handlePDFClick = (e, patient) => {
    e.preventDefault();
    this.setState({ isPDFPreviewModalShown: true, pdfModalURL: patient.pdf_link_primary });
  }

  hidePDFPreviewModal = () => {
    this.setState({ isPDFPreviewModalShown: false, pdfModalURL: null });
  }

  handlePrintQuestionnaireClicked = (e, user) => {
    this.setState({ isLoading: true });
    getPdfQuestionForm(user.id)
      .then((res) => {
        this.onPdfQuestionFormSuccess(res.result);
      })
      .catch((err) => {
        this.onPdfQuestionFormFailed(err.message);
      });
  }

  onPdfQuestionFormSuccess = (res) => {
    this.setState({ isLoading: false });
    successToast('Upitnik uspješno kreiran. Odobrite popups na vašem web pretraživaču da bi vam se upitnik automatski otvorio.');
    if (!isMobile) {
      window.open(res.path, '_blank', 'noopener,noreferrer');
    }
    else window.location.assign(res.path);

    window.location.reload(false);
  }

  onPdfQuestionFormFailed = (err) => {
    this.setState({ isLoading: false });
    console.log(err);
    errorToast('GREŠKA: ' + err);
  }

  handleUserDelete = (e, user) => {
    e.preventDefault();
    if (window.confirm(`Da li ste sigurni da želite sakriti pacijenta '${user.first_name} ${user.last_name}' iz tabele`)) {
      this.setState({ isLoading: true });
      clinicDeleteUser(user)
        .then((res) => {this.onUserDeleteSuccess(res.result)})
        .catch((err) => {this.onUserDeleteError(err.message)});
    }
  }

  onUserDeleteSuccess = (res) => {
    this.setState({ isLoading: false });
    successToast(`Pacijent '${res.first_name} ${res.last_name}' uspješno sakriven.`);
    this.updateDisplay(this.state.display, this.state.test_type_display);
  }

  onUserDeleteError = (err) => {
    this.setState({ isLoading: false });
    console.log(err);
    errorToast('GREŠKA: ' + err);
  }

  render() {
    const { display, users, usersDisplayed, activePage, lastUser, test_type_display } = this.state;
    return (
      <div className="main-cont">
        <ToastContainer
          newestOnTop
          limit={5}
        />
        {this.state.isLoading == true || this.props.transportFormLoading == true ? (
          <div className="text-center" style={{ marginTop: '150px', marginBottom: '150px' }}>
            <LoadingSpinner height={100} width={100} text="Učitavanje u toku. Molimo nemojte zatvarati ovaj prozor..." />
          </div>
        ) : (
          <main id="main" ref={this.racesRef}>
            <div class="container">
              {/* <div class="section-title" data-aos="fade-up">
                <br></br>
                <h2>Dodavanje pacijenta</h2>
              </div> */}
              {this.state.error && <div className="error-text big">{'Greška: ' + this.state.error}</div>}
              <div class="row">
                <div class="col-lg-2"> </div>
                <div class="col-lg-8">
                  {lastUser && lastUser.test_type != "PCR" ? (
                    <div>
                      <p>
                        Posljednji pacijent: {lastUser.last_name + ' ' + lastUser.first_name}{' '}
                        <a href={lastUser.pdf_link_primary}>(otvori PDF)</a>
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                  <div class="icon-box fivek" style={{ padding: "10px" }}>
                    {/* <div class="text-block text-center" >
                      {/* <h2>Bosanski jezik</h2> 
                      </div> */}
                    <div class="section-title" data-aos="fade-up">
                      <h2>Dodavanje pacijenta</h2>
                    </div>
                    <fieldset style={{ border: "1px solid #e6e0e0", padding: "15px", borderRadius: "5px" }}>
                      <legend style={{ width:"auto", fontSize: "14px", paddingLeft: "5px", paddingRight: "5px"}}>Osnovne informacije</legend>
                      <Row>
                        <Col sm>
                          <label for="first_name"><b>Ime *</b></label>
                          <br></br>
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            style={{ width: '100%' }}
                            onChange={(e) => this.handleInputChange(e, 'first_name')}
                          />
                        </Col>
                        <Col sm>
                          <label for="last_name"><b>Prezime *</b></label>
                          <br></br>
                          <input type="text" id="last_name" name="last_name" style={{ width: '100%' }} onChange={(e) => this.handleInputChange(e, 'last_name')} />
                        </Col>
                        <Col sm>
                        <label>
                          <b>Spol *</b>
                          </label>
                          <div onChange={this.handleSelectGender}>
                            <input type="radio" value="Male" name="gender" /> Muško {'             '}
                            <input type="radio" value="Female" name="gender" style={{ marginLeft: '20px' }} /> Žensko
                          </div>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col sm>
                          <label for="phone"><b>Broj telefona *</b></label>
                          <br></br>
                          <input type="text" id="phone" name="phone" style={{ width: '100%' }} onChange={(e) => this.handleInputChange(e, 'phone')} />
                        </Col>
                        <Col sm>
                        <label for="date_of_birth"><b>Datum rođenja *</b></label>
                          <br></br>
                          <DatePicker onChange={(e) => this.handleDateChange(e, 'date_of_birth')} value={this.state.date_of_birth} format="dd/MM/y" />
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col sm>
                          <label for="passport_num">Broj pasoša</label>
                          <br></br>
                          <input
                            type="text"
                            id="passport_num"
                            name="passport_num"
                            style={{ width: '100%' }}
                            onChange={(e) => this.handleInputChange(e, 'passport_num')}
                          />
                        </Col>
                        <Col sm>
                          <label for="email">Email</label>
                          <br></br>
                          <input type="email" id="email" name="email" style={{ width: '100%' }} onChange={(e) => this.handleInputChange(e, 'email')} />
                        </Col>
                      </Row>
                    </fieldset>

                    <br></br>

                    <fieldset style={{ border: "1px solid #e6e0e0", padding: "15px", borderRadius: "5px" }}>
                      <legend style={{width:"auto", fontSize: "14px", paddingLeft: "5px", paddingRight: "5px"}}>Informacije o testiranju</legend>
                    <Row>
                      <Col sm>
                      <label for="date_of_test"><b>Datum uzimanja uzorka *</b></label>
                        <br></br>
                        <DateTimePicker
                          onChange={(e) => this.handleDateChange(e, 'date_of_test')}
                          value={this.state.date_of_test.length == 0 ? Date.now() : new Date(this.state.date_of_test)}
                          format="dd/MM/y HH:mm"
                          disableClock="true"
                        />
                      </Col>
                      <Col sm>
                        {
                        this.state.user && !DISALLOWED_EMAILS_ANTIGEN.includes(this.state.user.email) ?
                        <div>
                          <label>
                            <b>Vrsta testa *</b>
                          </label>
                          <div onChange={this.handleSelectTestType}>
                            <input type="radio" value="PCR" name="test_type" /> PCR {'             '}
                            <input type="radio" value="ANTIGEN" name="test_type" style={{ marginLeft: '20px' }} /> ANTIGEN
                          </div>
                        </div> :
                        <div>
                          <label>
                            <b>Vrsta testa: PCR</b>
                          </label>
                        </div>
                        }
                      </Col>
                    </Row>
                    
                    <br></br>
                    {this.state.test_type == 'PCR' ? (
                      <div>
                        <label for="protocol_num">Broj protokola</label>
                        <br></br>
                        <input
                          type="text"
                          id="protocol_num"
                          name="protocol_num"
                          style={{ width: '100%' }}
                          onChange={(e) => this.handleInputChange(e, 'protocol_num')}
                        />
                        <br></br>
                        <br></br>
                        <label for="protocol_num"><b>Razlog testiranja *</b></label>
                        <br></br>
                        <div onChange={this.handleSelectTestReason}>
                          <input type="radio" value="PUTOVANJE" name="test_reason" /> Putovanje {'             '}
                          <input type="radio" value="SIMPTOMI" name="test_reason" style={{ marginLeft: '20px' }} /> Simptomi {'             '}
                          <input type="radio" value="KONTAKT SA ZARAŽENOM OSOBOM" name="test_reason" style={{ marginLeft: '20px' }} /> Kontakt sa zaraženom osobom
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {this.state.test_type == 'ANTIGEN' ? (<div>
                      <label>
                        <b>Rezultat testa *</b>
                      </label>
                      <div onChange={this.handleSelectIsPositive}>
                        <input type="radio" value="POZITIVAN" name="is_positive" /> POZITIVAN {'             '}
                        <input type="radio" value="NEGATIVAN" name="is_positive" style={{ marginLeft: '20px' }} /> NEGATIVAN
                      </div>
                    </div>) : ('')}
                    
                    </fieldset>
                    <br></br>
                    <p style={{ color: '#d40404', marginTop: "-10px" }}>* Potrebna polja</p>

                    <div class="text-center">
                      <button class="btn btn-sm box-btn" onClick={this.onSendClick}>
                        Pošalji
                      </button>
                    </div>

                  </div>
                  <br></br>
                </div>
                <div class="col-lg-2"> </div>
              </div>
            </div>
            <hr></hr>
            <br></br>
            <div class="section-title" data-aos="fade-up">
              <h2>Pacijenti</h2>
            </div>
            <div class="section-title" data-aos="fade-up">
              <p style={{ color: 'rgb(113 113 113)', fontSize: "14px", marginTop: "-30px" }}>novododani pacijenti se pojavljuju na vrhu prve stranice</p>
            </div>
            <section id="featured" class="featured" style={{ paddingTop: "0px" }}>
              <div class="container-fluid">
                <div class="row"></div>
                {this.state.user && !DISALLOWED_EMAILS_ANTIGEN.includes(this.state.user.email) ?
                  <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-3 text-center">
                      <div class="table-switch" onClick={() => this.switchTestType(0)} style={{ fontSize: "22px", color: test_type_display == 0 ? "#2273c9" : "#444444" }}>
                        PCR
                      </div>
                    </div>
                    <div class="col-md-3 text-center">
                      <div class="table-switch" onClick={() => this.switchTestType(1)} style={{ fontSize: "22px", color: test_type_display == 1 ? "#2273c9" : "#444444" }}>
                        ANTIGEN
                      </div>
                    </div>
                    <div class="col-md-3"></div>
                  </div>
                  : <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4 text-center">
                      <div class="table-switch" onClick={() => this.switchTestType(0)} style={{ fontSize: "22px", color: test_type_display == 0 ? "#2273c9" : "#444444" }}>
                        PCR
                      </div>
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                }

                {test_type_display == 0 &&
                  <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-3 text-center">
                      <div class="table-switch" onClick={() => this.switchDisplay(0)} style={{ fontSize: "22px", color: display == 0 ? "#2273c9" : "#444444" }}>
                        Pacijenti na čekanju
                      </div>
                    </div>
                    <div class="col-md-3 text-center">
                      <div class="table-switch" onClick={() => this.switchDisplay(1)} style={{ fontSize: "22px", color: display == 1 ? "#2273c9" : "#444444" }}>
                        Završeni pacijenti
                      </div>
                    </div>
                    <div class="col-md-3"></div>
                  </div>
                }
                {this.state.isTableLoading == true ? (
                  <div className="text-center" style={{ marginTop: '100px', marginBottom: '100px' }}>
                    <LoadingSpinner height={100} width={100} text="Učitavanje pacijenata..." />
                  </div>) :
                  <div class="row" style={{ marginTop: "50px" }}>
                    <div class="col-md-12">
                      <CustomTable
                        type={display}
                        user={this.state.user}
                        users={users}
                        usersDisplayed={usersDisplayed}
                        activePage={activePage}
                        perPage={this.PER_PAGE}
                        testType={this.state.test_type_display}
                        handlePageChange={this.handlePageChange.bind(this)}
                        updateUserTest={null}
                        sortUsers={this.sortUsers.bind(this)}
                        handleEditClick={this.handleEditClick.bind(this)}
                        handlePreviewClick={this.handlePreviewClick.bind(this)}
                        handlePDFClick={this.handlePDFClick.bind(this)}
                        handlePrintQuestionnaireClicked={this.handlePrintQuestionnaireClicked.bind(this)}
                        isPrintingLoading={this.state.isPrintingLoading}
                        handleUserDelete={this.handleUserDelete.bind(this)}
                      ></CustomTable>
                    </div>
                  </div>
                }
                <EditPatientModal
                  isEditModalShown={this.state.isEditModalShown}
                  hideEditModal={this.hideEditModal}
                  user={this.state.user}
                  patient={this.state.modalPatient}
                  onSendClick={this.onNewUserSendClick}
                />
                <PreviewPatientModal
                  isPreviewModalShown={this.state.isPreviewModalShown}
                  hidePreviewModal={this.hidePreviewModal}
                  user={this.state.user}
                  patient={this.state.modalPatient}
                />
                <PDFPreviewModal 
                  isPreviewModalShown={this.state.isPDFPreviewModalShown}
                  hidePreviewModal={this.hidePDFPreviewModal}
                  pdfURL={this.state.pdfModalURL}
                />
              </div>
            </section>

          </main>
        )}
      </div>
    );
  }
}
