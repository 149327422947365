import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import dateFormat from 'dateformat';

export default class CustomTable extends Component {

  state = {};

  componentDidUpdate() { }

  onPositive(e, user) {
    const prefix = "Jeste li sigurni da je pacijent ";
    const infix = user.first_name + " " + user.last_name + " ";
    const suffix = "POZITIVAN?";
    let date = new Date();
    if (user.status = "PENDING" && user.date_of_issuing_report_datetime && user.date_of_issuing_report) {
      date = user.date_of_issuing_report_datetime;
    }
    if (window.confirm(prefix + infix + suffix)) {
      this.props.updateUserTest(user, true, dateFormat(date, "dd/mm/yyyy HH:MM"));
    }
  }

  onNegative(e, user) {
    const prefix = "Jeste li sigurni da je pacijent ";
    const infix = user.first_name + " " + user.last_name + " ";
    const suffix = "NEGATIVAN?";
    let date = new Date();
    if (user.status = "PENDING" && user.date_of_issuing_report_datetime && user.date_of_issuing_report) {
      date = user.date_of_issuing_report_datetime;
    }
    if (window.confirm(prefix + infix + suffix)) {
      this.props.updateUserTest(user, false, dateFormat(date, "dd/mm/yyyy HH:MM"));
    }
  }

  mapUsers = (users) => {
    if (!users) return <div></div>
    const result =
      this.props.type == 0 ? users.map((user) => {
        // const status = user.test_status == "PENDING" ? "NA ČEKANJU" : "ZAVRŠEN";
        return <tr style={{ width: '100%' }}>
          <td>{user.id}</td>
          <td>{user.first_name}</td>
          <td>{user.last_name}</td>
          <td><a href="#" onClick={(e) => this.props.handlePreviewClick(e, user)}>Pregledaj</a></td>
          <td>{user.phone}</td>
          <td>{user.date_of_test instanceof Date ? dateFormat(user.date_of_test, "dd.mm.yyyy. HH:MM") : user.date_of_test}</td>
          {/* <td>{user.test_type}</td> */}
          {/* <td>{status}</td> */}
          {this.props.updateUserTest != null &&
            <td class="result-choose" style={{ color: "rgb(25 106 189)" }} onClick={(e) => this.onPositive(e, user)}><u>Odaberi</u></td>
          }
          {this.props.updateUserTest != null &&
            <td class="result-choose" style={{ color: "#bd2130" }} onClick={(e) => this.onNegative(e, user)}><u>Odaberi</u></td>
          }
          {this.props.testType == 1 ? "" :
            ((this.props.user.lab_id == null || this.props.user.lab_id <= 0) &&
              <td><a href="#" onClick={(e) => this.props.handleEditClick(e, user)}>Izmijeniti</a></td>)
          }
          {(this.props.user == null || (this.props.user.lab_id == null || this.props.user.lab_id <= 0)) && this.props.testType == 0 &&
            (
              this.props.isPrintingLoading ? <td>Ucitavam...</td> :
                (
                  // (user.pdf_questionnaire_link != null && <td><a href={user.pdf_questionnaire_link}>Printaj</a></td>) ||
                  // (user.pdf_questionnaire_link == null && <td><a href="#" onClick={(e) => this.props.handlePrintQuestionnaireClicked(e, user)}>Printaj</a></td>)
                  <td><a href="#" onClick={(e) => this.props.handlePrintQuestionnaireClicked(e, user)}>Printaj</a></td>

                )
            )
          }
          {
          user.is_positive == null ?
            <td><button href="#" onClick={(e) => this.props.handleUserDelete(e, user)} style={{ color: "rgb(212, 4, 4)", backgroundColor: "transparent", border: 0 }}>Sakrij</button></td>
            :
            <td>-</td>
          }
        </tr>
      })
        : users.map((user) => {
          // const status = user.test_status == "PENDING" ? "NA ČEKANJU" : "ZAVRŠEN";
          return <tr style={{ width: '100%' }}>
            <td>{user.id}</td>
            <td>{user.first_name}</td>
            <td>{user.last_name}</td>
            <td><a href="#" onClick={(e) => this.props.handlePreviewClick(e, user)}>Pregledaj</a></td>
            {/* <td>{user.passport_num ? user.passport_num : '/'}</td> */}
            {/* <td>{user.email ? user.email : '/'}</td> */}
            <td>{user.phone}</td>
            <td>{user.date_of_test}</td>
            <td>{user.date_of_issuing_report}</td>
            {/* <td>{user.test_type}</td> */}
            {/* <td>{status}</td> */}
            <td style={{ wordWrap: 'break-word' }}>
              <a href="#" onClick={(e) => this.props.handlePDFClick(e, user)}>LINK</a>
            </td>
            {/* <td style={{ wordWrap: 'break-word' }}>{user.pdf_link_en ? <a href={user.pdf_link_en}>LINK</a> : '/'}</td> */}
            {this.props.testType == 0 && <td><a href="#" onClick={(e) => this.props.handleEditClick(e, user)}>Izmijeniti</a></td>}
          </tr>
        })

    return result;
  };

  render() {
    const { users, usersDisplayed, activePage, perPage, type, testType } = this.props;
    return <div class="container-fluid">

      <div class="page">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={perPage}
          totalItemsCount={users.length}
          pageRangeDisplayed={8}
          onChange={this.props.handlePageChange.bind(this)}
          itemClassNext="next-page"
          itemClassLast="last-page"
          itemClassPrev="prev-page"
          itemClassFirst="first-page"
          prevPageText="<"
          nextPageText=">"
        />
      </div>

      {type == 0 ? <table border="1">
        <tr>
          <th>ID</th>
          <th>Ime</th>
          <th
            onClick={(e) => {
              this.props.sortUsers(e, 'last_name');
            }}
          >
            Prezime&#9662;
          </th>
          <th>Pregled nalaza</th>
          <th>Telefon</th>
          <th
            onClick={(e) => {
              this.props.sortUsers(e, 'date_of_test');
            }}
          >
            Datum uzimanja uzorka&#9662;
          </th>
          {/* <th>Vrsta testa</th> */}
          {/* <th>Status</th> */}
          {this.props.updateUserTest != null &&
            <th>Rezultat pozitivan</th>
          }
          {this.props.updateUserTest != null &&
            <th>Rezultat negativan</th>
          }
          {this.props.user == null || (this.props.user.lab_id == null || this.props.user.lab_id <= 0) &&
            <th>Izmijeniti</th>
          }
          {(this.props.user == null || (this.props.user.lab_id == null || this.props.user.lab_id <= 0)) && testType == 0 &&
            <th>Printanje upitnika</th>
          }
          <th>Sakrij</th>
        </tr>
        {this.mapUsers(usersDisplayed)}
      </table> : <table border="1">
        <tr>
          <th>ID</th>
          <th>Ime</th>
          <th
            onClick={(e) => {
              this.props.sortUsers(e, 'last_name');
            }}
          >
            Prezime&#9662;
          </th>
          <th>Pregled nalaza</th>
          {/* <th>Pasoš</th> */}
          {/* <th>Email</th> */}
          <th>Telefon</th>
          <th
            onClick={(e) => {
              this.props.sortUsers(e, 'date_of_test');
            }}
          >
            Datum uzimanja uzorka&#9662;
          </th>
          <th
            onClick={(e) => {
              this.props.sortUsers(e, 'date_of_issuing_report_datetime');
            }}
          >
            Datum verifikacije&#9662;
          </th>
          {/* <th>Vrsta testa</th> */}
          {/* <th>Status</th> */}
          <th>PDF</th>
          {/* <th style={{ color: 'rgb(148 148 148)' }}>PDF (engleski)</th> */}
          {this.props.testType == 0 && <th>Izmijeniti</th>}

        </tr>
        {this.mapUsers(usersDisplayed)}
      </table>}


    </div>
  }
}