import React, { Component } from 'react';
import SignInForm from '../components/SignInForm';
import { CLINIC_ROUTE, LAB_ROUTE, REPORTS_ROUTE, SIGNIN_ROUTE } from '../constants/routes';
import { setJwt, signIn } from '../services/http/authService';
import { getCurrentUser } from "../services/http/authService";

export default class SignIn extends Component {
  state = {
    isSubmitting: false,
    error: {},
  };

  componentDidMount() {
    const user = getCurrentUser();
    if (!user) {
      return;
    }
    this.redirect(user.lab_id, user.superuser);
  }

  onSubmit = (data) => {
    this.setState({ isSubmitting: true, error: {} });
    signIn(data)
      .then((res) => this.onSignInSuccessful(res))
      .catch((err) => this.onSignInError(err));
  };

  onSignInSuccessful = (res) => {
    const { result, headers } = res;
    setJwt(headers.get('x-auth'));
    this.setState({ isSubmitting: false });
    this.redirect(result.LabId, result.superuser);
  };

  redirect(labId, superuser) {
    if (labId != null) {
      window.location = LAB_ROUTE;
    } else if (superuser) {
      window.location = REPORTS_ROUTE;
    } else {
      window.location = CLINIC_ROUTE;
    }
    return;
  }

  onSignInError = (error) => {
    this.setState({ isSubmitting: false, error });
  };

  render() {
    const { isSubmitting, error } = this.state;
    return <SignInForm onSubmit={this.onSubmit} isSubmitting={isSubmitting} error={error} />;
  }
}
